import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { AuditExpense, BaseForm, FilterItem } from '@shared/classes';
import { ViewModeService } from '@shared/services';
import { AuditExpenseDataService } from '../../services/data/audit-expense-data.service';

@Component({
  selector: 'app-audit-expense-item-form',
  templateUrl: './audit-expense-item-form.component.html',
  styleUrl: './audit-expense-item-form.component.scss',
})
export class AuditExpenseItemFormComponent extends BaseForm<AuditExpense> implements OnInit {
  projectFilters: FilterItem[] = [{ property: 'auditProgram', operation: 'EQUAL', value: '' }];
  caseResultFilters: FilterItem[] = [{ property: 'auditProject', operation: 'EQUAL', value: '' }];

  constructor(
    public viewModeService: ViewModeService,
    private requestService: AuditExpenseDataService
  ) {
    super(viewModeService, 'AUDIT_EXPENSE');
  }

  ngOnInit(): void {
    this.formGroup.controls.auditProgram.valueChanges.subscribe((x) => {
      this.formGroup.controls.auditProject.patchValue(null);
      this.formGroup.controls.caseResult.patchValue(null);
      this.projectFilters = [{ property: 'auditProgram', operation: 'EQUAL', value: x }];
    });

    this.formGroup.controls.auditProject.valueChanges.subscribe((x) => {
      this.caseResultFilters = [{ property: 'auditProject', operation: 'EQUAL', value: x }];
    });
  }

  getData() {
    return this.viewModeService.viewMode == 'create'
      ? this.getDataKeyValueFormat()
      : this.getChangedFormValues().updateItems;
  }

  setData(data: any) {
    this.patchFormData({ ...data });
    this.data = data;
  }

  initFormStructure(): void {
    this.formGroup = new FormGroup({
      name: new FormControl(null, Validators.required),
      auditor: new FormControl(null, Validators.required),
      actualExpenses: new FormControl(null, Validators.required),
      actualResources: new FormControl(null, Validators.required),
      auditProgram: new FormControl(null, Validators.required),
      auditProject: new FormControl(null, Validators.required),
      caseResult: new FormControl(null, Validators.required),
      description: new FormControl(null),
    });
  }
}
